import React, { useState } from 'react';
import axios from 'axios';
import TestservLogo from '../assets/images/TestservLogo.svg';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { testservDescription, companyLinks, featuresLinks, resourcesLinks, socialMediaData, newsLetterContent, copyrightText, newsLetterURL, errorMessages, failedSubmit, thanksForSubscribe, placeholders, emailRegex } from '../constants/Constants';

const FooterLinkColumn = ({ title, links }) => {
    return (
        <div className="text-left pb-4">
            <h5 className="text-gray-700 text-lg font-semibold mb-4">{title}</h5>
            <ul>
                {links.map((link, index) => (
                    <li key={index} className='mb-3'>
                        <a
                            className="text-gray-500 hover:text-navy"
                            href={link.href}
                        >
                            {link.text}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const Footer = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const validateEmail = () => {
        if (!email.trim()) {
            return errorMessages.emailRequired;
        } else if (!emailRegex.test(email)) {
            return errorMessages.emailInvalid;
        }
        return null; 
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const emailError = validateEmail();
        if (emailError) {
            setError(emailError);
            return; 
        }

        try {
            const response = await axios.post(newsLetterURL, {
                emailAddress: email
            });
            if (response.status === 200) {
                setEmail('');
                setError('');
                setSuccess(true); 
                setTimeout(() => {
                    setSuccess(false);
                }, 5000);
            }
        } catch (error) {
            alert(failedSubmit, error);
        }
    };
     
    return (
        <footer className="bg-white text-gray-700">
            <div className="py-10">
                <div className="grid lg:grid-cols-7 lg:gap-4 mx-auto px-10">
                    <div className='lg:col-span-2 -mt-4 me-6 pb-4'>
                        <a href="/">
                            <img alt="logo" className="h-12 w-auto" src={TestservLogo} />
                        </a>
                        <p className='text-justify text-sm pt-3'>{testservDescription}</p>
                    </div>

                    <FooterLinkColumn title="Company" links={companyLinks} />
                    <FooterLinkColumn title="Features" links={featuresLinks} />
                    <FooterLinkColumn title="Resources" links={resourcesLinks} />

                    <div className="lg:col-span-2 text-left pb-4">
                        <h5 className="text-gray-900 text-lg font-semibold mb-4">{newsLetterContent.title}</h5>
                        <p className='mb-4 text-justify text-sm max-w-80'>{newsLetterContent.description}</p>
                        <form className="flex items-center" onSubmit={handleSubmit} noValidate>
                            <input
                                className="px-3 py-2 border border-gray-300 rounded-lg outline-none focus:bg-gray-100 w-full"
                                id="email"
                                name="email"
                                placeholder= {placeholders.email}
                                maxLength={50}
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const containsEmojis = value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/);
                                    if (!containsEmojis) {
                                        setEmail(value);
                                    }
                                }}
                            />
                            <button type="submit" className="px-4 py-2 bg-navy text-white rounded-full ms-2">
                                <HiOutlineArrowNarrowRight />
                            </button>
                        </form>
                        {error && <div className="text-red-500 mt-2">{error}</div>}
                        {success && <div className="text-green-500 mt-2">{thanksForSubscribe}</div>}

                        <div className="mt-2">
                            <small className="text-gray-600">{newsLetterContent.privacyMessage}</small>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="subfooter bg-lightwhite py-2">
                <div className="mx-12">
                    <div className="flex flex-col justify-between items-center md:flex-row md:justify-between">
                        <div className="mb-4 md:mb-0">
                            {copyrightText}
                        </div>
                        <div className="flex items-center space-x-4">
                            {socialMediaData.map((item) => (
                                <a
                                    key={item.title}
                                    href={item.href}
                                    target="_blank"
                                    className="navy-color-bg text-white px-2 rounded-full"
                                    title={item.title}
                                    rel="noopener noreferrer"
                                >
                                    {React.cloneElement(item.icon, { className: 'h-8' })}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
