import React, { useState } from "react";
import axios from 'axios';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { contactUsForm, offices, errorMessages, contactURL, thanksForContact, placeholders, emailRegex } from "../constants/Constants";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {

    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{23E9}-\u{23FA}\u{1F900}-\u{1F9FF}\u{1F170}-\u{1F251}]/gu, '');
        let updatedErrors = { ...errors };
    
        switch (name) {
            case 'name':
                sanitizedValue = sanitizedValue.replace(/[0-9]/g, '');
                delete updatedErrors.name;
                break;
            case 'email':
                delete updatedErrors.email;
                break;
            case 'phone':
                const phoneNumber = parsePhoneNumberFromString(sanitizedValue);
                sanitizedValue = phoneNumber ? phoneNumber.formatInternational() : sanitizedValue;
                delete updatedErrors.phone;
                break;
            case 'message':
                delete updatedErrors.message;
                break;
            default:
                break;
        }
    
        setFormData(prevState => ({
            ...prevState,
            [name]: sanitizedValue
        }));
    
        setErrors(updatedErrors);
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await axios.post(contactURL, {
                    name: formData.name,
                    emailAddress: formData.email,
                    phoneNumber: formData.phone,
                    message: formData.message
                });
                setErrors({})
                navigate('/thank_you', { state: { text: thanksForContact, backRoute: '/contact_us'} }); 
                setFormData(initialFormData);
            } catch (error) {
                alert('Error submitting form', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        const phoneNumber = parsePhoneNumberFromString(data.phone);
    
        switch (true) {
            case !data.name.trim():
                errors.name = errorMessages.name;
                break;
            case !data.email.trim():
                errors.email = errorMessages.emailRequired;
                break;
            case !emailRegex.test(data.email):
                errors.email = errorMessages.emailInvalid;
                break;
            case !data.phone.trim():
                errors.phone = errorMessages.phoneRequired;
                break;
            case !phoneNumber || !phoneNumber.isValid():
                errors.phone = errorMessages.phoneInvalid;
                break;
            case !data.message.trim():
                errors.message = errorMessages.message;
                break;
            default:
                break;
        }
        return errors;
    };
    

    return (
        <div id="ContactUsSection" className="bg-gray-50 py-10">
            <h3 className="text-2xl md:text-3xl font-bold mb-2 text-center text-gray-700">{contactUsForm.title}</h3>
            <div className="mx-auto px-4 lg:flex lg:justify-between lg:items-start">
                <div className="lg:w-2/3 lg:mr-4 mt-8 bg-white text-left p-8 rounded-lg shadow-md">
                    <h3 className="text-2xl font-semibold mb-6">{contactUsForm.formTitle}</h3>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="mb-4">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100`}
                                placeholder={placeholders.name}
                                maxLength={25}
                                required
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100`}
                                placeholder={placeholders.email}
                                maxLength={50}
                                required
                            />
                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`w-full px-3 py-2 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100`}
                                placeholder={placeholders.phone}
                                maxLength={16}
                                required
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-4">
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className={`w-full h-56 px-3 py-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100`}
                                placeholder={placeholders.message}
                                title=""
                                maxLength={250}
                                required
                            ></textarea>
                            {errors.message && <p className="text-red-500">{errors.message}</p>}
                        </div>
                        <div className="mb-4">
                            <button
                                type="submit"
                                className="bg-navy hover:bg-blue-900 text-white font-semibold py-2.5 px-5 rounded-full outline-none"
                            >
                                {contactUsForm.submitBtnText}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="lg:w-1/3 lg:ml-4 mt-8">
                    {Object.values(offices).map((office, index) => (
                        <div
                            key={index}
                            className="w-full text-left p-6 rounded-lg shadow-lg mb-6 hover:bg-white hover:text-gray-800 bg-navy text-white"
                        >
                            <h3 className="text-xl font-semibold mb-4">{office.title}</h3>
                            <address>
                                <span className="flex items-center mb-2">
                                    <FaMapMarkerAlt className="id-color mr-2" style={{ flex: "none" }} />
                                    <span className="flex-grow">{office.address}</span>
                                </span>
                                <span className="flex items-center mb-2">
                                    <FaPhone className="id-color mr-2" />
                                    {office.phone}
                                </span>
                                <span className="flex items-center mb-2">
                                    <FaEnvelope className="id-color mr-2" />
                                    <a href={`mailto:${office.email}`} className="hover:text-secondary hover:scale-105">{office.email}</a>
                                </span>
                            </address>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
