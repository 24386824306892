import React, { useState } from 'react';
import { whyChooseHeading, whyChooseContent } from '../constants/Constants';

const WhyChooseUs = () => {
    const [selectedId, setSelectedId] = useState(whyChooseContent[0].id); // Set initial state to the id of the first item

    return (
        <div className='bg-lightwhite py-8 pt-12'>
            <h1 className="text-2xl md:text-3xl font-bold mb-2 text-center text-gray-700 pt-6 md:pt-2">{whyChooseHeading}</h1>
            <div className='m-10 grid md:grid-cols-3 lg:grid-cols-5 '>
                {whyChooseContent.map(item => (
                    <button
                        key={item.id}
                        type='button'
                        className={`text-md lg:text-lg text-black md:hover:text-navy hover:border-gray-500 font-semibold border border-black rounded-lg h-14 md:h-20 px-2 mx-1 md:mx-2 my-1 transition-all ease-in-out ${selectedId === item.id ? 'bg-navy text-white border-0' : ''}`}
                        onClick={() => setSelectedId(item.id)}
                        style={{ pointerEvents: selectedId === item.id ? 'none' : 'auto' }}
                    >
                        {item.heading}
                    </button>
                ))}
            </div>

            {selectedId !== null && (
                <div className='m-10 grid md:grid-cols-2 gap-8 transition-all ease-in'>
                    <div className='px-2'>
                        <h2 className="text-xl md:text-2xl font-semibold text-gray-700">{whyChooseContent.find(item => item.id === selectedId).heading}</h2>
                        <p className="text-gray-700 text-md md:text-lg italic py-4 text-justify">{whyChooseContent.find(item => item.id === selectedId).description}</p>
                    </div>
                    
                        <img src={whyChooseContent.find(item => item.id === selectedId).imageLink} alt={whyChooseContent.find(item => item.id === selectedId).heading} className="w-full h-full object-cover rounded-2xl" />
                  
                </div>
            )}

        </div>
    );
}

export default WhyChooseUs;
