import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FaCalendarDays } from "react-icons/fa6";
import { IoMdPlayCircle } from "react-icons/io";
import VideoImage from '../assets/images/VideoImage.png';
import YouTubePlayer from './Youtube_Video';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';
import { headingText, normalText, appointmentButton, errorMessages, serviceOptions, placeholders, videoLink, demoURL, thanksForDemo, emailRegex } from '../constants/Constants';

const initialValues = {
    name: '',
    email: '',
    phone: '',
    date: null,
    department: '',
    message: ''
};

const DemoSection = () => {
    const [formData, setFormData] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedErrors = { ...errors };
        let sanitizedValue = value.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{23E9}-\u{23FA}\u{1F900}-\u{1F9FF}\u{1F170}-\u{1F251}]/gu, '');

        switch (name) {
            case 'name':
                sanitizedValue = sanitizedValue.replace(/[0-9]/g, '');
                delete updatedErrors.name;
                break;
            case 'email':
                delete updatedErrors.email;
                break;
            case 'phone':
                const phoneNumber = parsePhoneNumberFromString(sanitizedValue);
                sanitizedValue = phoneNumber ? phoneNumber.formatInternational() : sanitizedValue;
                delete updatedErrors.phone;
                break;
            case 'date':
                delete updatedErrors.date;
                break;
            case 'department':
                delete updatedErrors.department;
                break;
            default:
                break;
        }

        setFormData(prevState => ({
            ...prevState,
            [name]: sanitizedValue
        }));

        setErrors(updatedErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const demoDateTimeString = formData.date ? formData.date.toISOString().slice(0, 16).replace('T', ' ') : null;
                const response = await axios.post(demoURL, {
                    name: formData.name,
                    emailAddress: formData.email,
                    phoneNumber: formData.phone,
                    demoDateAndTime: demoDateTimeString,
                    department: formData.department,
                    description: formData.message
                });
                setErrors({});
                navigate('/thank_you', { state: { text: thanksForDemo, backRoute: '/demo' } });
                setFormData(initialValues);
            } catch (error) {
                alert('Error submitting data:', error);
            }
        } else {
            setErrors(validationErrors);
        }
    };


    const validateForm = () => {
        let updatedErrors = {};
        const phoneNumber = parsePhoneNumberFromString(formData.phone);

        switch (true) {
            case !formData.name.trim():
                updatedErrors.name = errorMessages.name;
                break;
            case !formData.email.trim():
                updatedErrors.email = errorMessages.emailRequired;
                break;
            case !emailRegex.test(formData.email):
                updatedErrors.email = errorMessages.emailInvalid;
                break;
            case !formData.phone.trim():
                updatedErrors.phone = errorMessages.phoneRequired;
                break;
            case !phoneNumber || !phoneNumber.isValid():
                updatedErrors.phone = errorMessages.phoneInvalid;
                break;
            case !formData.date:
                updatedErrors.date = errorMessages.date;
                break;
            case !formData.department:
                updatedErrors.department = errorMessages.department;
                break;
            default:
                break;
        }
        setErrors(updatedErrors);
        return updatedErrors;
    };

    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            date: date
        }));
    };


    return (
        <section id="appointment" className="bg-sky-100 pt-12 pb-28">
            <div className="bg-thikgray mx-4 md:mx-8 rounded-2xl md:rounded-[45px] grid lg:grid-cols-2 lg:gap-4 px-4 md:px-16">
                <div className="py-8 flex flex-col md:flex-row lg:flex-col justify-between">
                    <div>
                        <h2 className="text-xl md:text-3xl font-semibold text-gray-50 mb-4">{headingText}</h2>
                        <p className="text-lg md:text-xl text-justify text-gray-200 w-11/12 lg:w-10/12">{normalText}</p>
                    </div>
                    <div className='relative mt-7 lg:me-16 cursor-pointer'>
                        <img src={VideoImage} alt='Video' className='max-w-[100%] rounded-2xl' onClick={() => setShowModal(true)} />
                        <span className="absolute top-2 right-2">
                            <span className="animate-ping transition-opacity ease-in-out duration-100 inline-flex h-8 w-8 rounded-full bg-white opacity-100 absolute left-2 top-2"></span>
                            <span className="opacity-100 relative w-12 cursor-pointer" onClick={() => setShowModal(true)}><IoMdPlayCircle size={50} /></span>
                            {showModal && (
                                <>
                                    <div className="flex flex-col justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-6" style={{ backgroundColor: 'rgba(255,255,255,0.8)' }}>
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center w-full h-full outline-none focus:outline-none" >
                                            <button type="button" onClick={() => setShowModal(false)} className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                </svg>
                                            </button>
                                            <YouTubePlayer videoId={videoLink} />
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            )}
                        </span>
                    </div>
                </div>
                <div className='bg-white rounded-2xl md:rounded-[45px] px-4 md:px-12 pt-12 pb-5 lg:pb-2 shadow-2xl relative top-12'>
                    <form onSubmit={handleSubmit} className='grid gap-4' noValidate>
                        <div className="w-full">
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className={`px-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                id="name"
                                placeholder={placeholders.name}
                                minLength={4}
                                maxLength={25}
                                aria-label={placeholders.name}
                            />
                            {errors.name && <p className="text-red-500">{errors.name}</p>}
                        </div>
                        <div className="w-full">
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                id="email"
                                placeholder={placeholders.email}
                                maxLength={50}
                                aria-label={placeholders.email}
                            />
                            {errors.email && <p className="text-red-500">{errors.email}</p>}
                        </div>
                        <div className="w-full">
                            <input
                                type="tel"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className={`px-3 py-2 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                id="phone"
                                placeholder={placeholders.phone}
                                maxLength={16}
                                aria-label={placeholders.phone}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="w-full">
                            <div className='relative'>
                                <DatePicker
                                    selected={formData.date}
                                    onChange={handleDateChange}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa 'IST'"
                                    placeholderText={formData.date ? formData.date.toLocaleString() + ' IST' : placeholders.date}
                                    minDate={new Date()}
                                    className={`px-3 py-2 border ${errors.date ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                    id="date"
                                    aria-label={placeholders.date}
                                    timeZone="Asia/Kolkata"
                                />
                                <FaCalendarDays className='text-gray-700 absolute top-3 right-5' />
                            </div>
                            {errors.date && <p className="text-red-500">{errors.date}</p>}
                        </div>
                        <div className="w-full">
                            <div className="relative flex-grow">
                                <select
                                    name="department"
                                    value={formData.department}
                                    onChange={handleChange}
                                    className={`appearance-none px-3 py-2 border ${errors.department ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                    id="department"
                                    aria-label={placeholders.department}
                                >
                                    {serviceOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                <FontAwesomeIcon icon={faCaretDown} className="absolute top-3 right-5 text-gray-700" />
                            </div>
                            {errors.department && <p className="text-red-500 text-xs ml-1">{errors.department}</p>}
                        </div>
                        <div className="w-full">
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                className={`px-3 py-2 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg outline-none focus:bg-gray-100 w-full`}
                                rows="5"
                                placeholder={placeholders.message_optional}
                                maxLength={250}
                                aria-label={placeholders.message_optional}
                            ></textarea>
                            {errors.message && <p className="text-red-500">{errors.message}</p>}
                        </div>
                        <div className="w-full text-center">
                            <button type="submit" className="bg-navy hover:bg-blue-900 text-white font-semibold py-3 px-7 rounded-full focus:outline-none focus:shadow-outline mb-4">{appointmentButton}</button>
                        </div>
                    </form>
                </div>
            </div>
        </section >
    );
};

export default DemoSection;
