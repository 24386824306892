import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationLinks = ({ links }) => {
  const [menuStates, setMenuStates] = useState(links.map(() => false));
  const [submenuRef, setSubmenuRef] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveTab(currentPath);
  }, []);

  const handleMenuHover = (index, isOpen) => {
    const newMenuStates = [...menuStates];
    newMenuStates[index] = isOpen;
    setMenuStates(newMenuStates);
  };

  const handleSubmenuEnter = () => {
    setMenuStates((prevStates) => prevStates.map(() => true));
  };

  const handleSubmenuLeave = () => {
    setMenuStates((prevStates) => prevStates.map(() => false));
  };

  const handleSetActiveTab = (href) => {
    setActiveTab(href);
  };

  const isItemActive = (item) => {
    return activeTab === item.href;
  };

  const isLinkActive = (link) => {
    return activeTab === link.href || link.submenu?.some(isItemActive);
  };

  return (
    <div className="hidden lg:flex lg:gap-x-8">
      {links.map((link, index) => (
        <div key={link.label} className="relative group">
          {link.submenu ? (
            <Menu as="div">
              <div
                onMouseEnter={() => handleMenuHover(index, true)}
                onMouseLeave={() => handleMenuHover(index, false)}
              >
                <Menu.Button
                  className={classNames(
                    'flex items-center text-sm font-semibold leading-6',
                    'hover:text-navy outline-none',
                    isLinkActive(link) ? 'text-navy' : 'text-gray-900'
                  )}
                  onMouseEnter={() => setSubmenuRef(link.label)}
                  onMouseLeave={() => setSubmenuRef(null)}
                  onClick={() => handleSetActiveTab(link.href)}
                >
                  {link.label} {link.submenu && <FontAwesomeIcon icon={faCaretDown} className="ml-1 text-gray-700" />}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                show={menuStates[index]}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items
                  static
                  className="origin-top-right text-start absolute mt-5 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                  onMouseEnter={handleSubmenuEnter}
                  onMouseLeave={handleSubmenuLeave}
                  ref={(ref) => setSubmenuRef(ref)}
                >
                  <div className="py-1">
                    {link.submenu?.map((item, itemIndex) => (
                      <Menu.Item key={itemIndex}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900',
                              isItemActive(item) ? 'text-navy bg-gray-100' : 'text-gray-700'
                            )}
                            onClick={() => handleSetActiveTab(item.href)}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <a
              href={link.href}
              className={classNames(
                'flex items-center text-sm font-semibold leading-6',
                'hover:text-navy outline-none',
                isLinkActive(link) ? 'text-navy' : 'text-gray-900' // Apply different style for active tab
              )}
              onClick={() => handleSetActiveTab(link.href)}
            >
              {link.label}
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export default NavigationLinks;
