import React, { useState, useEffect } from 'react';
import { BlogsContent, Blogs } from '../constants/Constants';

// Shuffle function (Fisher-Yates Shuffle)
const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const generateBlogColumn = (
  blogs,
  animationDuration,
  animationDelayMultiplier,
  isHovered,
  setAnimationState,
  columnIndex
) => {
  // Dynamic delay calculation based on the columnIndex
  const delay = -columnIndex * animationDuration * animationDelayMultiplier;

  return (
    <div
      className='column-container'
      style={{
        animation: `moveUp ${animationDuration}s infinite linear ${animationDelayMultiplier}s`,
        animationPlayState: isHovered ? 'paused' : 'running', 
        animationDelay: `${delay}s`,
      }}
      onMouseEnter={() => setAnimationState(true)}
      onMouseLeave={() => setAnimationState(false)}
    >
      {blogs.map((blog, index) => (
        <div
          key={index}
          className="blog-card bg-white rounded-2xl p-6 mb-8 text-left shadow-lg"
          style={{ animationPlayState: isHovered ? 'paused' : 'running' }} 
        >
          <h2 className="text-lg text-gray-900 font-semibold mb-2">"{blog.title}"</h2>
          <p className="text-gray-700 text-md leading-7 mb-4 text-justify">{blog.description}</p>
          <a
            href={blog.link}
            className="text-blue-500 text-bold hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            - {BlogsContent.readmore}
          </a>
        </div>
      ))}
    </div>
  );
};

const BlogList = () => {
  const [isHovered1, setHovered1] = useState(false);
  const [isHovered2, setHovered2] = useState(false);
  const [isHovered3, setHovered3] = useState(false);
  const [shuffledBlogs1, setShuffledBlogs1] = useState([]);
  const [shuffledBlogs2, setShuffledBlogs2] = useState([]);
  const [shuffledBlogs3, setShuffledBlogs3] = useState([]);

  useEffect(() => {
    setShuffledBlogs1(shuffleArray([...Blogs, ...Blogs, ...Blogs, ...Blogs]));
    setShuffledBlogs2(shuffleArray([...Blogs, ...Blogs, ...Blogs, ...Blogs]));
    setShuffledBlogs3(shuffleArray([...Blogs, ...Blogs, ...Blogs, ...Blogs]));
  }, []);

  return (
    <div id="Blogs" className="mx-auto bg-gray-50 p-10 py-20 overflow-hidden h-[920px] pb-5">
      <h1 className="text-2xl md:text-3xl font-bold mb-2 text-center text-gray-700">{BlogsContent.title}</h1>
      <p className="text-lg my-6 mx-auto max-w-4xl text-center">{BlogsContent.description}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 overflow-hidden relative py-16 my-20 bg-for-blogs h-[580px]">
        <div>
          {generateBlogColumn(shuffledBlogs1, 100*2, 0.01, isHovered1, setHovered1, 0)}
        </div>
        <div>
          {generateBlogColumn(shuffledBlogs2, 160*2, 0.01, isHovered2, setHovered2, 1)}
        </div>
        <div>
          {generateBlogColumn(shuffledBlogs3, 100*2, 0.01, isHovered3, setHovered3, 2)}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
