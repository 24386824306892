import ManAI from '../assets/images/ManAI.png';
import FEAutomationReport from '../assets/images/FEAutomationReport.png';
import FeTestingPage from '../assets/images/FeTestingPage.png';
import Smartphone from '../assets/images/Smartphone.png';
import { BannerFEContent } from '../constants/Constants';

const FEBanner = () => {
    return (
        <div id="FEDashboard" className="relative overflow-hidden pt-10 sm:pt-20 bg-thikgray">
            {BannerFEContent.map((section, index) => (
                <div key={index} className="container mx-auto my-5 text-center text-gray-50">
                    {section.heading && <h2 className="sec-heading text:sm md:text-xl font-medium">{section.heading}</h2>}
                    {section.title && <h1 className="text-2xl md:text-4xl font-bold mb-4">{section.title}</h1>}
                    {section.description && <p className="text-md md:text-lg my-8 mx-auto max-w-4xl">{section.description}</p>}
                </div>
            ))}
            <div className="bg-image">
                <div>
                    {BannerFEContent.map((section, index) => (
                        section.features && (
                            <div key={index} className="flex flex-col md:flex-row justify-center items-center md:justify-evenly md:-mb-14 lg:-mb-24">
                                {section.features.map((feature, featureIndex) => (
                                    <div
                                        key={featureIndex}
                                        className={`bg-gray-300 p-4 transition-transform transform hover:scale-105 rounded-2xl relative mt-2 mx-4 sm:mx-0 w-80 md:w-64 md:h-28 ${feature.class}`}
                                    >
                                        <h3 className="text-sm sm:text-md font-bold mb-1">{feature.heading}</h3>
                                        <p className="text-xs sm:text-sm xl:text-md font-normal">{feature.description}</p>
                                    </div>
                                ))}
                            </div>
                        )
                    ))}
                </div>

                <div className="w-full flex justify-center items-center relative">
                    <div className='relative mt-10 lg:mt-20'>
                        <img src={FeTestingPage} alt='FE Testing' className='h-auto max-w-full lg:max-w-[420px] rounded-lg' />
                        <img src={FEAutomationReport} alt='FE Report' className='h-auto max-w-full lg:max-w-[380px] rounded-lg absolute top-5 left-5 md:top-10 md:left-16' />
                    </div>
                    <img src={ManAI} alt='AI Man' className="max-w-[50%] h-full lg:max-h-[600px] md:w-auto rounded-lg md:relative md:right-10 z-20" />
                    <img src={Smartphone} alt='Smart Phone' className="max-w-[20%] lg:max-h-[350px] rounded-lg" />
                </div>

            </div>
        </div>
    )
}

export default FEBanner;
