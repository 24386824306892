import React from 'react';
import { useLocation } from 'react-router-dom';
import {thankYouHeading, back} from '../constants/Constants';

const ThankYou = () => {

    const location = useLocation();
    const { text, backRoute } = location.state;

    return (
        <div className="flex items-center justify-center h-96 bg-white">
            <div className="rounded">
                <div className="flex flex-col items-center space-y-2 bg-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-navy w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth='1'>
                        <path strokeLinecap='round' strokeLinejoin='round' d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h1 className="text-3xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-navy via-secondary to-complementory">{thankYouHeading}</h1>
                    <p className='w-9/12 text-center text-lg text-gray-700'>{text}</p>
                    <a href={backRoute}
                        className="inline-flex items-center px-5 py-2 text-white bg-indigo-600 border border-indigo-600 rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>
                        <span className="text-md font-medium">
                            {back}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ThankYou;