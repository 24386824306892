import React from 'react';
import { termsAndConditionsheading, termsAndConditions, feedbackInfo } from '../constants/Constants';

const TermDescription = ({ description }) => (
  <div className='text-justify'>
    {Object.values(description).map((desc, i) => (
      <div key={i} className='my-2'>
        {desc}
      </div>
    ))}
  </div>
);

const TermList = ({ list }) => (
  <ul className='list-disc text-justify ms-6'>
    {Object.values(list).map((item, j) => (
      <li key={j} className='my-2'>
        {item}
      </li>
    ))}
  </ul>
);

const TermSection = ({ term }) => (
  <div className='text-justify my-6'>
    <h1 className='font-semibold underline'>{term.heading}</h1>
    {term.description && <TermDescription description={term.description} />}
    {term.list && <TermList list={term.list} />}
  </div>
);

const FeedbackSection = ({ feedback }) => (
  <div className='my-6'>
    <h1 className='font-semibold underline'>{feedback.heading}</h1>
    <div className='text-justify my-4'>{feedback.description_1}<a href={`mailto:${feedback.email_link}`} className='text-complementory underline ms-2'>{feedback.email_link}</a></div>
    <div className='text-justify my-4'>{feedback.description_2}</div>
  </div>
);

const TermsAndConditionsSection = () => (
  <div>
    <div className='w-full bg-gray-200 flex flex-col justify-center items-center p-8'>
      <div className='w-full bg-white md:w-10/12 shadow-lg rounded-xl py-4 px-8'>
        <h5 className="text-gray-900 underline text-xl md:text-2xl text-center font-semibold m-4">
          {termsAndConditionsheading}
        </h5>
        {Object.values(termsAndConditions).map((term, index) => (
          <TermSection key={index} term={term} />
        ))}
        <FeedbackSection feedback={feedbackInfo} />
      </div>
    </div>
  </div>
);

export default TermsAndConditionsSection;
