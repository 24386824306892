import React from 'react';
import { socialMediaData } from '../constants/Constants';

const Topbar = () => {
  return (
    <div id="topbar" className="flex justify-end text-white navy-color-bg h-12 shadow-lg">
      <div className="flex items-center me-5">
      {socialMediaData.map((item) => (
                <a
                    key={item.title}
                    href={item.href}
                    target="_blank"
                    title={item.title}
                >
                    {React.cloneElement(item.icon)}
                </a>
            ))}      </div>
      <div className="clearfix"></div>
    </div>
  );
};

export default Topbar;
