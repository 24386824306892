import React, { useState, useEffect } from 'react';
import './App.css';
import './assets/css/Styles.css';
import Topbar from './components/SocialMedia_Topbar';
import Header from './components/Header';
import Home from './components/Home';
import APIBanner from './components/API_Banner';
import FEBanner from './components/FE_Dashboard_Banner';
import BlogList from './components/Blogs';
import FaqSection from './components/FAQs' ;
import Footer from './components/Footer';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <Topbar />
      <div className='home-bg'>
        <Header isScrolled={isScrolled} logo={'white'} />
        <Home />
      </div>
      <APIBanner />
      <FEBanner />
      <BlogList />
      <FaqSection />
      <Footer />
    </div>
  );
}

export default App;
