import React from "react";
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faqsHeading, faqsDescription, faqs } from "../constants/Constants";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

const FaqSection = () => {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    return (
        <div className="bg-white py-16">
            <h1 className="text-2xl md:text-3xl font-semibold text-center text-gray-700 mb-6">{faqsHeading}</h1>
            <p className="text-lg md:text-lg text-gray-700 mb-8 mx-10 md:mb-10">{faqsDescription}</p>
            <div className="grid grid-cols-1 md:grid-cols-2">
                {Object.keys(faqs).map((section, sectionIndex) => (
                    <div key={sectionIndex}>
                        {faqs[section].map((faq) => (
                            <div key={faq.id} className={`border rounded-tl-xl rounded-tr-xl m-3 px-5 ${open === faq.id ? 'accordion-open' : 'accordion-button'}`}>
                                <Accordion open={open === faq.id} icon={
                                    <FontAwesomeIcon
                                        icon={open === faq.id ? faMinus : faPlus}
                                        style={{
                                            transform: `rotate(${open === faq.id ? '0deg' : '90deg'})`,
                                            transition: 'transform 0.3s ease-in-out'
                                        }}
                                    />
                                }>
                                    <AccordionHeader className={`text-md font-semibold text-gray-900 hover:text-gray-500 ${open === faq.id ? 'text-navy' : ''}`} onClick={() => handleOpen(faq.id)}>{faq.question}</AccordionHeader>
                                    <AccordionBody className="text-justify text-md font-normal">
                                        {faq.answer}
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FaqSection;
