import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoId }) => {
    const opts = {
        playerVars: {
            origin: window.location.origin,
        },
    };

    return <YouTube videoId={videoId} opts={opts} />;
};

export default YouTubePlayer;
