import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import TestservLogo from '../assets/images/TestservLogo.svg';
import WhiteTestserv from '../assets/images/WhiteTestserv.svg';
import SAndTLogo from '../assets/images/SAndTLogo.svg'
import NavigationLinks from './Navigation_Links';
import { links, LOGIN, loginURL } from '../constants/Constants';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const MobileMenu = ({ isOpen, onClose, links }) => (
  <Dialog as="div" className="lg:hidden" open={isOpen} onClose={onClose}>
    <div className="fixed inset-0 z-10" />
    <Dialog.Panel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
      <div className="flex items-center justify-between">
        <a href="/" className="-m-1.5 p-1.5">
          <img
            className="h-8 w-auto"
            src={SAndTLogo}
            alt="TestServ Logo"
          />
        </a>
        <button
          type="button"
          className="-m-2.5 rounded-md p-2.5 text-gray-700"
          onClick={onClose}
        >
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6">
        <ul className="space-y-4">
          {links.map((link) => (
            <li key={link.label}>
              <a
                href={link.href}
                className="-mx-3 block rounded-lg px-3 py-1 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                onClick={onClose}
              >
                {link.label}
              </a>

              {link.submenu && (
                <div className="pl-6 mt-2 space-y-2">
                  {link.submenu.map((subLink) => (
                    <div key={subLink.name}>
                      <a
                        href={subLink.href}
                        className="block rounded-lg pl-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        onClick={onClose}
                      >
                        {subLink.name}
                      </a>

                      {subLink.submenu && (
                        <div className="pl-6 mt-2 space-y-2">
                          {subLink.submenu.map((subSubLink) => (
                            <a
                              key={subSubLink.name}
                              href={subSubLink.href}
                              className="block rounded-lg pl-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                              onClick={onClose}
                            >
                              {subSubLink.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>

        <div className="py-6">
          <a
            href={loginURL}
            className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 shadow-lg"
            onClick={onClose}
            target='_blank'
          >
            {LOGIN}
          </a>
        </div>
      </div>

    </Dialog.Panel>
  </Dialog>
);

const Header = ({ isScrolled, logo }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className='flex flex-col'>
      <header className={`header-part transition-transform ease-in-out duration-50 ${isScrolled ? 'header-scrolled bg-lightwhite shadow-lg fixed top-0 z-30 w-full' : ''}  ${logo === 'main' ? 'bg-lightwhite shadow-lg' : ''}`}>
        <nav className="mx-auto flex max-w-7xl xl:max-w-full items-center justify-between px-6 py-4 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1 ms-3">
            <a href="/" className="-m-1.5 p-1.5">
              <img className={`h-8 ${isScrolled ? "md:h-14 w-auto" : "md:h-[62px]"}`} src={logo === 'main' ? TestservLogo : (isScrolled ? TestservLogo : WhiteTestserv)} alt="TestServ Logo" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <NavigationLinks links={links} />
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href={loginURL} target='_blank' className="text-sm font-semibold leading-6 text-white navy-color-bg px-6 py-1 rounded-full shadow-lg">
              <span aria-hidden="true"><FontAwesomeIcon icon={faArrowDown} /></span> {LOGIN}
            </a>
          </div>
        </nav>

        <MobileMenu isOpen={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} links={links} />
      </header>

    </div>
  );
};

export default Header;
