import React from 'react';
import { typedStrings, HomeContent, TypingEffect } from "../constants/Constants";
import HomeDesktop from '../assets/images/HomeDesktop.png';

const Home = () => {
    return (
        <div id="HomeSection" className="py-5">
            <div className="flex flex-col sm:flex-row justify-around items-start mx-auto my-10">
                <div className="text-black text-center md:text-left md:ml-12 md:mt-12">
                    <h1 className="text-4xl md:text-5xl font-bold">
                        {HomeContent.title}
                        <br />
                        {HomeContent.subtitle}
                        <TypingEffect strings={typedStrings} />
                        <>|</>
                    </h1>
                    <p className="leading-6 mt-3 md:mt-5 text-md md:text-lg text-center sm:text-justify">{HomeContent.description}</p>
                    <div className='mt-4 md:mt-8'>
                        {HomeContent.buttons.map((button, index) => (
                            <a key={index} href={button.link} className={`text-xs md:text-sm font-semibold px-4 md:px-12 py-2 md:py-3 rounded-full shadow-xl ${button.class}`}>
                                {button.label}
                            </a>
                        ))}
                    </div>
                </div>
                <div className="mt-6 md:mt-0 md:ml-6">
                    <img src={HomeDesktop} alt="Desktop Screen" className="w-full md:w-auto" />
                </div>
            </div>
        </div>
    );
};

export default Home;
