import { BannerAPIContent } from "../constants/Constants";
import WomenAI from '../assets/images/WomanAI.png';
import ReportScreen from '../assets/images/ReportScreen.jpg';
import WatermarkLogo from '../assets/images/WatermarkLogo.jpg';
import TestData from '../assets/images/TestData.jpg'

const APIBanner = () => {
  return (
    <div id="APIAutomation" className="bg-lightgray">
      <div className="relative overflow-hidden pt-10 sm:pt-20 text-black">
        {BannerAPIContent.map((section, index) => (
          <div key={index} className="container mx-auto text-center">
            {section.heading && <h2 className="sec-heading text:sm md:text-xl font-medium">{section.heading}</h2>}
            {section.title && (
              <h1 className="text-2xl md:text-4xl md:w-6/12 mx-auto font-bold mb-4">{section.title}</h1>
            )}
            {section.description && <p className="text-md md:text-lg my-8 mx-auto max-w-4xl">{section.description}</p>}


            {section.features && (
              <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:-mt-20 gap-4 mx-6 sm:mx-auto max-w-3xl`}>
                {section.features.map((feature, featureIndex) => (
                  <div
                    key={featureIndex}
                    className={`bg-secondary text-white p-4 transition-transform transform hover:scale-105 rounded-xl relative z-20 ${feature.class}`}
                  >
                    <h3 className="text-md font-bold mb-1">{feature.heading}</h3>
                    <p className="text-sm font-normal">{feature.description}</p>
                  </div>
                ))}
              </div>
            )}


          </div>
        ))}

        <div className="w-full flex justify-center items-center relative mt-4 md:mt-0">
        <img src={ReportScreen} alt='Report' className="h-auto max-w-[45%] md:max-w-[40%] lg:max-w-[460px] rounded-lg relative top-20 md:top-32 left-32 lg:left-20" />
          <img src={WomenAI} alt='AI Women' className=" max-w-[50%] lg:max-h-[600px] md:w-auto rounded-lg z-20" />
          <img src={TestData} alt="Test Data" className="h-auto max-w-[45%] md:max-w-[40%] lg:max-h-[380px] rounded-lg relative bottom-2 right-24 md:right-36 z-10"/>
        </div>
        <div className="absolute bottom-2 right-2 md:bottom-6 md:right-20 bg-no-repeat h-32 w-auto">
          <img src={WatermarkLogo} alt="Watermark" className="h-full w-full" />
        </div>
      </div>
    </div>
  );
};


export default APIBanner;
