import React from 'react';
import CuttingImage from '../assets/images/CuttingImage.gif';
import AboutUs2 from '../assets/images/AboutUs2.gif';
import { aboutDesctiption, vision, withTestServ, visionTypeString, TypingEffect, visionSideHead, visionPara1, visionPara2 } from '../constants/Constants';
import WhyChooseUs from './Why_Choose_Us';

const AboutUs = () => {
    return (
        <div id="AboutUs" className=''>
            <div className='p-8 md:p-20 text-white bg-secondary'>
                <p className='md:text-4xl md:leading-[3rem] w-10/12 text-justify py-8'>{aboutDesctiption}</p>
            </div>
            <div className='py-40'>
                <p className='text-lg md:text-2xl text-gray-800 text-center mx-auto w-9/12'>{vision}</p>
                <h1 className='text-2xl md:text-4xl lg:text-7xl mx-auto w-9/12 font-semibold my-5 md:ps-16 lg:ps-10'>{withTestServ} <TypingEffect strings={visionTypeString}/><span className='text-green-500'>|</span></h1>
            </div>
            <div className='relative'>
            <img src={AboutUs2} alt='About Gif' className='w-full'/>
                <div className='grid md:grid-cols-3 gap-6 pt-3 md:pt-16 px-3 lg:max-h-[70vh]'>
                <div className='relative bg-slate-100 md:-top-48 md:mx-4 h-24 md:h-[26rem] flex flex-col items-center justify-center' style={{backgroundImage: `url(${CuttingImage})`, backgroundSize: 'cover'}}>
                        <p className=' md:text-2xl lg:text-4xl text-white p-10 '>{visionSideHead}</p>
                    </div>
                    <div className='text-gray-700 mx-2'>
                        <p className='md:text-lg lg:text-xl md:leading-8 text-justify'>{visionPara1}</p>
                    </div>
                    <div className='text-gray-700 mx-2 mb-6 md:mb-0'>
                        <p className='md:text-lg lg:text-xl md:leading-8 text-justify'>{visionPara2}</p>
                    </div>
                </div>
            </div>

            <WhyChooseUs />
            
        </div>
    )
}

export default AboutUs