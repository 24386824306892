import React, { useState, useEffect } from "react";
import { FaLinkedin, FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import CommitmentQuality from "../assets/images/whyChooseUs/CommitmentQuality.gif";
import CustomerCentricApproach from "../assets/images/whyChooseUs/CustomerCentricApproach.gif";
import IndustryExpertise from "../assets/images/whyChooseUs/IndustryExpertise.gif";
import InnovativeTechnology from "../assets/images/whyChooseUs/InnovativeTechnology.gif";
import ReliableSupport from "../assets/images/whyChooseUs/ReliableSupport.gif";
//
// Social Media constants for Topbar and Footer Sections

export const socialMediaData = [
  {
    href: "https://www.linkedin.com/company/100438716/admin/feed/posts/",
    icon: <FaLinkedin />,
    title: "LinkedIn",
  },
  {
    href: "https://twitter.com/TestServAi",
    icon: <FaXTwitter />,
    title: "Twitter",
  },
  {
    href: "https://www.instagram.com/testserv.ai?igsh=MXYzemU3cXJqMnFyeA==",
    icon: <FaInstagram />,
    title: "Instagram",
  },
  {
    href: "https://www.facebook.com/profile.php?id=61552843293518",
    icon: <FaFacebook />,
    title: "Facebook",
  },
  {
    href: "https://www.youtube.com/@TestServAi",
    icon: <FaYoutube />,
    title: "YouTube",
  },
];

// Header Section Constants

export const LOGIN = "LOGIN";
export const loginURL = "https://login.testserv.ai";

export const links = [
  { label: "HOME", href: "/" },
  {
    label: "FEATURES",
    submenu: [
      { name: "API Automation", href: "/api_Automation" },
      { name: "FE Dashboard Report", href: "/fe_dashboard" },
    ],
  },
  { label: "BLOGS", href: "/blogs" },
  { label: "ABOUT US", href: "/about_us" },
  { label: "DEMO", href: "/demo" },
  { label: "CONTACT US", href: "/contact_us" },
];

// Home Section Constants

export const typedStrings = ["Generative AI", "Low-Code", "No-Code"];

export const HomeContent = {
  title: "Automation Testing",
  subtitle: "using ",
  description:
    "Your ultimate solution for generating robust test data and cases, expanding test coverage, and easily uncovering critical edge cases.",
  buttons: [
    {
      label: "LEARN MORE",
      link: "#",
      class:
        "text-medium text-gray-900 border border-solid border-1 border-gray-900 me-2 hidden",
    },
    { label: "DEMO", link: "/demo", class: "text-medium text-white bg-navy" },
  ],
};

// API Banner Section COnstants

export const BannerAPIContent = [
  {
    heading: "API AUTOMATION",
    title: "Every aspect of API testing, now simplified and automated",
    description:
      "With our API automation solution, gone are the days of manual test case creation and validation. Our platform revolutionizes API testing, automating every step from test case creation to validation. Move beyond manual processes to seamless, efficient testing with TestServ.",
  },
  {
    subtitle: "Key Features:",
    features: [
      {
        heading: "Test case generation",
        description:
          "Create detailed, comprehensive test scenarios with the click of a button.",
        class: "feature-1",
      },
      {
        heading: "Schema Validation",
        description:
          "Ensure your APIs meet their specifications flawlessly every time.",
        class: "feature-2",
      },
      {
        heading: "Relational API Testing",
        description:
          "Simulate real-world use cases and API interactions with ease.",
        class: "feature-3",
      },
      {
        heading: "Centralized Repository of Reports",
        description:
          "All your test data, organized in one secure, accessible hub.",
        class: "feature-4",
      },
      {
        heading: "Intelligent Report Analysis",
        description: "Turn data into decisions with advanced analytical tools.",
        class: "feature-5",
      },
    ],
  },
];

// FE Dashboard Banner Section Constants

export const BannerFEContent = [
  {
    heading: "FE DASHBOARD REPORT",
    title: "Real-time Insights, Clear Analytics, One Dashboard",
    description:
      "Using a dynamic dashboard, the testing team can gain visibility into  API test executions. This timely information enables stakeholders to make well-informed decisions, thus enhancing productivity and efficiency.",
  },
  {
    subtitle: "Key Features:",
    features: [
      {
        heading: "Centralized Reports",
        description:
          "Get a bird's-eye view of your testing outcomes, all in one place.",
        class: "",
      },
      {
        heading: "Intelligent Report Analysis",
        description:
          "Discover patterns, track progress, and identify issues before they escalate.",
        class: "",
      },
    ],
  },
];

// Blogs Section Constants

export const BlogsContent = {
  title: "BLOGS",
  description:
    "Welcome to our blog, where we delve into the latest trends, insights, and in the world of automated testing. Stay updated with insightful analysis and thought-provoking content.",
  readmore: "Read More",
};

export const Blogs = [
  {
    id: 1,
    title: "Revolutionizing API Testing: Introducing TestServ",
    description:
      "In the fast-paced world of software development, Application Programming Interfaces (APIs) serve as the backbone for seamless data exchange and integration...",
    link: "https://testserv-ai.blogspot.com/2023/10/TestServAPI%20Testing.html",
  },
  {
    id: 2,
    title: "The Benefits of No-Code Platforms",
    description:
      "No-code platforms are becoming increasingly popular in the software testing industry, as they offer many benefits for testers, developers, and business stakeholders... ",
    link: "https://testserv-ai.blogspot.com/2024/02/the-benefits-of-no-code-platforms-for.html",
  },
  {
    id: 3,
    title: "Advancements in AI-Driven Software Testing",
    description:
      "In the dynamic realm of software development, the pursuit of quality and efficiency has given rise to a groundbreaking ally – Artificial Intelligence (AI)...",
    link: "https://testserv-ai.blogspot.com/2024/02/advancements-in-ai-driven-software.html",
  },
  {
    id: 4,
    title: "How Automation Testing Helps Businesses Achieve Success",
    description:
      "Software development is a fast-paced and competitive field, where businesses need to deliver high-quality applications that meet the needs and expectations of their customers. To achieve this, businesses need to ensure that their software is reliable, functional, and error-free...",
    link: "https://testserv-ai.blogspot.com/2024/02/how-automation-testing-helps-businesses.html",
  },
  {
    id: 5,
    title:
      "No Code, Codeless, and Low Code Testing Explained: What They Are and How They Differ",
    description:
      "Software testing is an essential part of the development process, but it can also be challenging and time-consuming...",
    link: "https://testserv-ai.blogspot.com/2024/03/no-code-codeless-and-low-code-testing.html",
  },
  {
    id: 6,
    title: "No-Code Testing vs. Manual Testing",
    description:
      "In this rapidly changing world of software development, the debate on testing methodologies has taken the center stage...",
    link: "https://testserv-ai.blogspot.com/2024/03/no-code-testing-vs-manual-testing.html",
  },
  {
    id: 7,
    title: "AI's Role in Automation Testing",
    description:
      "Picture a world where software development seamlessly navigates through constant changes and technological evolution...",
    link: "https://testserv-ai.blogspot.com/2024/03/ais-role-in-automation-testing.html",
  },

  {
    id: 8,
    title: "Simplifying Testing Workflows with Scriptless Test Automation",
    description:
      "In the dynamic landscape of software development, the need for agile and efficient testing processes has never been more critical...",
    link: "https://testserv-ai.blogspot.com/2024/03/simplifying-testing-workflows-with.html",
  },
  {
    id: 9,
    title: "The Evolution of Software Testing",
    description:
      "Software testing has undergone significant changes over the years, adapting to the shifting landscapes of technology and development practices...",
    link: "https://testserv-ai.blogspot.com/2024/04/the-evolution-of-software-testing.html",
  },
  {
    id: 10,
    title: "Strategies for Seamless Testing Workflows",
    description:
      "Creating a seamless testing workflow is essential for delivering software that is both robust and reliable...",
    link: "https://testserv-ai.blogspot.com/2024/04/strategies-for-seamless-testing.html ",
  },
  {
    id: 11,
    title: "Overcoming Common Challenges in Software Testing",
    description:
      "Software testing is an essential phase in the software development lifecycle, aimed at ensuring applications perform as expected and are free of bugs...",
    link: "https://testserv-ai.blogspot.com/2024/04/overcoming-common-challenges-in.html ",
  },
  {
    id: 12,
    title: "Benefits of Drag and Drop Testing Tools",
    description:
      "In the world of software development finding efficient ways to ensure product quality is always a top priority. It’s like crafting a masterpiece; every detail counts...",
    link: "https://testserv-ai.blogspot.com/2024/04/benefits-of-drag-and-drop-testing.html",
  },
  {
    id: 13,
    title: "Benefits of Using pre-Built Components",
    description:
      "Imagine you are baking a cake for a special occasion. You could either make everything from scratch, or you could use a pre-mixed batter...",
    link: "https://testserv-ai.blogspot.com/2024/04/benefits-of-using-pre-built-components.html",
  },
  {
    id: 14,
    title: "Advantages of Centralized Report Repository in Software Testing",
    description:
      "Every software testing team has been there; sifting through multiple versions of test reports, trying to figure out which findings are current and which have been superseded...",
    link: "https://testserv-ai.blogspot.com/2024/04/advantages-of-centralized-report.html ",
  },
  {
    id: 15,
    title: "Accelerating Time-to-Market with Automated Testing Pipelines",
    description:
      "Every software development team faces the challenge of balancing speed with quality. In a competitive market, the faster a product reaches the customer, the better. Yet, rushing software to launch without testing risks bugs...",
    link: "https://testserv-ai.blogspot.com/2024/05/accelerating-time-to-market-with.html",
  },
  {
    id: 16,
    title: "Unlocking Developer Potential via No-Code Testing",
    description:
      "Developers are the architects of the digital world, crafting intricate codes that power our everyday experiences. However the traditional approach to testing often hinders their creativity, bogging them down in complex coding languages...",
    link: "https://testserv-ai.blogspot.com/2024/05/unlocking-developer-potential-via-no.html",
  },
  {
    id: 17,
    title: "Exploring AI-driven Test Case Generation",
    description:
      "Imagine having a tireless assistant by your side, tirelessly churning test cases with precision and efficiency, allowing you to focus on what truly matters – delivering high-quality software...",
    link: "https://testserv-ai.blogspot.com/2024/05/exploring-ai-driven-test-case-generation.html",
  },
  {
    id: 18,
    title: "Exploring Benefits of Intelligent Report Analysis",
    description:
      "Navigating through piles of reports can often feel like an overwhelming task, but Intelligent Report Analysis is transforming this process into a strategic advantage. By leveraging advanced technologies, organizations can extract meaningful insights, stream-line decision-making, and enhance operational efficiency...",
    link: "https://testserv-ai.blogspot.com/2024/05/exploring-benefits-of-intelligent.html",
  },
  {
    id: 19,
    title: "Advantages of a Front-End Dashboard Report",
    description:
      "When it comes to front-end dashboard reports, the sheer variety of features can be overwhelming...",
    link: "https://testserv-ai.blogspot.com/2024/05/advantages-of-front-end-dashboard-report.html",
  },
  {
    id: 20,
    title: "Best Practices for Implementing Codeless Test Automation",
    description:
      "Navigating the complexities of software testing can be daunting, especially for those without a coding background. Codeless test automation offers a solution, enabling more team members to participate in testing without requiring deep technical skills...",
    link: "https://testserv-ai.blogspot.com/2024/05/best-practices-for-implementing.html",
  },
  {
    id: 21,
    title: " Choosing the Best Testing Platform for Your Development Needs",
    description:
      "Selecting the right testing platform can significantly impact your development process, ensuring quality and efficiency...",
    link: "https://testserv-ai.blogspot.com/2024/06/choosing-best-testing-platform-for-your.html",
  },
  {
    id: 22,
    title: "The Rise of AI and No-Code Testing Solutions",
    description:
      "Technology continues to advance at a rapid pace, and one of the most transformative areas in software development is the rise of AI and no-code testing solutions...",
    link: "https://testserv-ai.blogspot.com/2024/06/the-rise-of-ai-and-no-code-testing.html",
  },
  {
    id: 23,
    title: "The Role of Automation in Relational API Testing",
    description:
      "Imagine you're a software developer, tasked with ensuring that a complex web of services works seamlessly together...",
    link: "https://testserv-ai.blogspot.com/2024/06/the-role-of-automation-in-relational.html",
  },
  {
    id: 24,
    title: "Automation Testing Using No-Code Approach",
    description:
      "The complexities of software development often extend into the realm of testing, where ensuring quality and functionality can be an arduous task...",
    link: "https://testserv-ai.blogspot.com/2024/06/automation-testing-using-no-code.html",
  },
  {
    id: 25,
    title:
      "Top 3 Challenges in API Testing and How to Overcome them with TestServ",
    description:
      "You’re working on a project that relies heavily on multiple APIs, and everything seems to be running smoothly until an unexpected issue surfaces, throwing your entire timeline into chaos...",
    link: "https://testserv-ai.blogspot.com/2024/06/top-3-challenges-in-api-testing-and-how.html",
  },
  {
    id: 26,
    title: "Improving Test Coverage with API Automation",
    description:
      "Testing is the integral part of software development ensuring that the applications run smoothly and meet all required specifications...",
    link: "https://testserv-ai.blogspot.com/2024/06/improving-test-coverage-with-api.html",
  },
  {
    id: 27,
    title: "Key Differences between Manual and Automated Test Case Generation",
    description:
      "Understanding the differences between manual and automated test case generation is crucial for optimizing your software testing strategy. Both methods have distinct characteristics that can impact the efficiency, effectiveness, and coverage of your tests...",
    link: "https://testserv-ai.blogspot.com/2024/07/key-differences-between-manual-and.html",
  },
  {
    id: 28,
    title: "Challenges in Test Case Generation",
    description:
      "Generating test cases is a crucial aspect of software testing, ensuring that all parts of an application are thoroughly examined. However, this process comes with several challenges that can hinder its effectiveness and efficiency...",
    link: "https://testserv-ai.blogspot.com/2024/07/challenges-in-test-case-generation.html",
  },
  {
    id: 29,
    title: "Top Emerging Trends of AI in Test Automation for 2024",
    description:
      "The landscape of software testing is evolving at an unprecedented pace, driven largely by the latest trends of AI...",
    link: "https://testserv-ai.blogspot.com/2024/08/top-emerging-trends-of-ai-in-test.html",
  },
  {
    id: 30,
    title: "The Impact of Automated Testing on reducing Time to Market",
    description:
      "The need to deliver high-quality software quickly has become a top priority for companies across industries...",
    link: "https://testserv-ai.blogspot.com/2024/08/the-impact-of-automated-testing-on.html",
  },
];

// Demo Section Constants

export const headingText = "Request for Demo";

export const normalText = `We're excited to show you how our Automation Testing using AI can transform your testing industry and help you achieve your goals. Please fill out the form below to request a personalized demo. Our team will get in touch with you to schedule a convenient time.`;

export const appointmentButton = "Make an Appointment";

export const placeholders = {
  name: "Name",
  email: "Email ID",
  phone: "Phone Number with Country Code",
  date: "Select Date and Time",
  department: "Select Service",
  message: "Message",
  message_optional: "Message (Optional)",
};

export const serviceOptions = [
  { value: "", label: "Service Needed" },
  { value: "API Automation", label: "API Automation" },
  { value: "Dashboard Report", label: "FE Dashboard Report" },
];

export const errorMessages = {
  name: "Name is Required",
  nameInvalid: "Name should contain only alphabets and spaces",
  emailRequired: "Email is Required",
  emailInvalid: "Email is Invalid",
  phoneRequired: "Phone Number is Required",
  phoneInvalid: "Phone Number is Invalid",
  date: "Date is Required",
  department: "Service Needed is Required",
  message: "Message is Required",
};

export const successMessage = "Form Submitted Successfully!";
export const videoLink = "r5fqlXFMQq8";

// FAQ's Section Constants

export const faqsHeading = "Frequently Asked Questions";
export const faqsDescription =
  "If you can’t find what you’re looking for, email our support team and if you’re lucky someone will get back to you";

export const faqs = {
  section1: [
    {
      id: 1,
      question: "Why is API Testing Important?",
      answer:
        "API Testing is crucial to ensure that APIs work as intended, meet business requirements, and maintain data integrity, as they are integral components of modern software applications.",
    },
    {
      id: 2,
      question: "How does TestServ simplify software testing?",
      answer:
        "TestServ simplifies testing by offering a no-code approach. This means users can create and manage tests intuitively without the need for complex scripting. The platform utilizes visual interfaces, drag-and-drop tools, and pre-built components for ease of use.",
    },
    {
      id: 3,
      question: "What is AI in the context of API Testing?",
      answer:
        "AI refers to the use of artificial intelligence, specifically generative models, to automate and assist in the generation of test cases, data, and scenarios for API testing.",
    },
  ],
  section2: [
    {
      id: 4,
      question: "How can AI help in generating test data for API Testing?",
      answer:
        "AI can assist in identifying security vulnerabilities by simulating attacks and generating security test cases and including edge cases, to ensure thorough testing. AI-generated tests are comprehensive, handling dynamic data, managing false positives/negatives, and adapting to evolving APIs.",
    },
    {
      id: 5,
      question: "What makes TestServ's AI tool significant for testing?",
      answer:
        "TestServ's AI tool is a powerful asset for automation testing. It excels in creating intricate test cases and data, improving test coverage, and identifying elusive edge cases that might be missed with manual testing methods.",
    },
    {
      id: 6,
      question:
        "Can TestServ integrate with existing testing frameworks or tools?",
      answer:
        "Yes, TestServ is designed to integrate seamlessly with existing testing frameworks and tools. This allows users to leverage their current testing setup while benefiting from TestServ's advanced features.",
    },
  ],
};

// Contact Us Section Constants

export const contactUsForm = {
  title: "Contact Us",
  formTitle: "Do you have any Questions?",
  submitBtnText: "Submit Form",
};

export const offices = {
  INDIA: {
    title: "INDIA",
    address:
      "UDN ALTIUS Building, Third Floor, No. 13, JP Nagar 6th Phase, Bengaluru, Karnataka 560078",
    phone: "+91 8049605900",
    email: "info@testserv.ai",
  },
  CANADA: {
    title: "CANADA",
    address: "485 - 6400 Roberts Street Burnaby, BC V5G 4C9 CANADA",
    phone: "+1(236)818-3825",
    email: "contact@ifocussystems.ca",
  },
  SINGAPORE: {
    title: "SINGAPORE",
    address: "160 Robinson RD, #14-04 SBF Center, SINGAPORE 068914",
    phone: "+91 80 49605900",
    email: "info@ifocussystec.com",
  },
};

// About Us Section Constants

export const aboutUsHeading = "About Us";
export const aboutDesctiption =
  "Welcome to TestServ, your trusted partner in software testing solutions. At TestServ, we're dedicated to simplifying and optimizing the testing process, ensuring that businesses can deliver high-quality software products with confidence.";
export const vision =
  "Our vision at TestServ is to revolutionize the way software testing is conducted, making it accessible, efficient, and reliable for businesses worldwide.";
export const withTestServ = "With TestServ ";
export const visionTypeString = ["Innovate.", "Automate.", "Accelerate."];
export const visionSideHead = "Effortless Testing, Exceptional Quality ";
export const visionPara1 =
  "We understand the challenges associated with ensuring software quality in today’s fast-paced digital landscape. That’s why we developed a suite of cutting-edge testing tools and services designed to streamline workflows, increase productivity, and enhance the overall quality of software products.";
export const visionPara2 =
  "With TestServ, you can trust that your testing needs are in good hands. Our team is committed to providing exceptional support and guidance every step of the way, helping you navigate the complexities of software testing with ease. ";

// Why Choose Us Section Constants

export const whyChooseHeading = "Why Choose Us?";

export const whyChooseContent = [
  {
    id: 1,
    heading: "Industry Expertise",
    description:
      "We are a team of professionals with extensive experience in the software industry.  This depth of knowledge equips us to tackle the challenges of software testing with finesse, offering insightful solutions tailored to your specific needs. Whether it's identifying critical bugs or optimizing testing processes, our expertise ensures that your software undergoes thorough evaluation, resulting in robust and reliable outcomes.",
    imageLink: IndustryExpertise,
  },
  {
    id: 2,
    heading: "Commitment to Quality",
    description:
      "Quality isn't just a goal; it's our guiding principle. From the meticulous planning of our testing processes to the final delivery of results, we uphold the highest standards of quality and accuracy. With us, you can trust that your software undergoes rigorous testing to meet and exceed industry benchmarks. Our unwavering commitment to quality ensures that your software performs flawlessly, providing a seamless user experience that fosters trust and loyalty among your customers.",
    imageLink: CommitmentQuality,
  },
  {
    id: 3,
    heading: "Customer-Centric Approach",
    description:
      "Client needs are our priority. We take the time to understand your unique requirements and preferences, working alongside you every step of the way to ensure that our testing solutions align perfectly with your goals. Our attentive and collaborative approach ensures that you receive personalized service and unwavering support throughout your testing journey. From initial consultations to ongoing assistance, we're dedicated to exceeding your expectations and delivering results that drive your success.",
    imageLink: CustomerCentricApproach,
  },
  {
    id: 4,
    heading: "Innovative Technology",
    description:
      "Embracing cutting-edge automation technology, we revolutionize the testing experience, making it faster, more efficient, and error-free. By harnessing the power of automation, we streamline your testing processes, freeing up valuable time and resources for tackling more pressing challenges. Our innovative solutions leverage advanced algorithms and AI-driven techniques to identify potential issues early in the development cycle, enabling you to address them proactively and minimize risks. With our forward-thinking approach, we empower you to stay ahead of the curve and drive innovation in your industry.",
    imageLink: InnovativeTechnology,
  },
  {
    id: 5,
    heading: "Reliable Support",
    description:
      "We're more than just a testing provider; we're your dedicated partners in success. With our unwavering support and guidance, you can tackle the challenges of software testing with confidence. From initial consultations to ongoing assistance, we're here to ensure that you receive the help you need, precisely when you need it. With our responsive customer support team and comprehensive training resources, you can rest assured that you're in good hands every step of the way.",
    imageLink: ReliableSupport,
  },
];

// Terms and Conditions Section Constants
export const termsAndConditionsheading = "Terms and Conditions";

export const termsAndConditions = {
  termsOfUse: {
    heading: "TERMS OF USE:",
    description: {
      description_1:
        'To the fullest extent permitted by law, these Website Terms of Use constitute a legally binding contract between You (or Your) and Us. Your use of Our Website and all the content, text, graphics, statistics, photographs, information, logo, software and more ("Materials/Resources") as well as any products, tools or services that We make accessible or provide on Our Website are governed by these Terms and Conditions.',
      description_2:
        "The Terms are a legally binding contract between Ifocus Technologies (India) Pvt. Ltd., (Herein after referred to as IFOCUS TECHNOLOGIES for brevity) and you. If you use our website or any of the services provided on it, you agree to be legally bound by the Terms. Do not use the Website or any services offered if you do not agree to all of the Terms. You acknowledge and agree that by using the Website and any service provided, that you have read and you understand and agree to the Terms and this indicates your acceptance of, and agreement to be legally bound by the Terms.",
      description_3:
        "If you continue to use the Websites or services after the changes have been posted, you agree to the revised Terms, even if you haven't examined them. It is your responsibility to monitor the Websites for updates and changes to the Terms posted there.",
    },
  },
  productInfo: {
    heading: "PRODUCT INFORMATION:",
    description: {
      description_1:
        "TestServ  is a product of Ifocus Technologies and stands at the forefront of software testing innovation, pioneering no-code solutions that transform how businesses conquer testing challenges. With a mission to simplify complex processes, we empower organizations with unparalleled ease and efficiency, redefining the testing landscape.",
    },
  },
  usingWithoutAuth: {
    heading: "USING OUR WEBSITE WITHOUT AUTHORIZATION:",
    description: {
      description_1:
        "Except as expressly provided above, all rights, titles, and interests are reserved. No other use of Our website are permitted without our written permission. Any other type of usage of Our website is strictly prohibited. Unauthorized use of our website and materials may result in an infringement of India's and other nations' intellectual property rights.",
      description_2:
        "We respect other people's intellectual property rights and ask that you do the same. We reserve the right to discontinue access to and use of Our Website in suitable circumstances and at our discretion.",
    },
  },
  terminateAccess: {
    heading: "TERMINATING YOUR ACCESS TO OUR WEBSITE:",
    description: {
      description_1:
        "If You violate any of these Website Terms of Usage, your use of Our Website will be promptly terminated. To be clear:",
    },
    list: {
      list_1:
        "You have the right to stop using and accessing Our Website at any time.",
      list_2:
        "Unless we agree otherwise in writing, we reserve the right to suspend, restrict, terminate or limit Your access to or use of part or all of Our Website at any point of time and for whatever reason. You must promptly destroy any downloaded or printed Materials, as well as all copies of such Materials, if you are terminated for breach.",
      list_3:
        "You agree not to engage in any activity that may harm, disrupt, or abuse our website, including but not limited to hacking, spamming, or distributing malware.",
    },
  },
  liabilityLimitation: {
    heading: "LIABILITY LIMITATION:",
    description: {
      description_1:
        "We will not be accountable in any way in connection with Our Website or these: for any consequential, special, indirect, incidental, punitive or any other type of damage (including, but not limited to loss of income or business opportunities or data) that isn't expressly stated herein (including, but not limited to, personal data) or programs (including, but not limited to, the expense of program or data recovery or replacement); Any damage, loss or expenses incurred as a result of a disruption, delay, interruption, or inability to utilize any of Our services or products.",
      description_2:
        "However, even if we have been expressly notified of the likelihood of these kind of damages, they may occur. Furthermore, nothing in this agreement excludes or limits any obligation that cannot be excluded or limited by relevant legislation.",
    },
  },
  layoutDesign: {
    heading: "LAYOUT/ DESIGN:",
    description: {
      description_1:
        "Ifocus Technologies owns the layout and design of this website. Copies, duplicates, reproduction, retransmission or imitations of any part of the Websites' content are strictly prohibited due to copyright protection, trade dress, and other regulations. You may not copy or transmit any logo, picture, sound, graphic or image unless Ifocus Technologies  expressly permits it.",
    },
  },
  otherAgreements: {
    heading: "OTHER AGREEMENTS:",
    description: {
      description_1:
        "Other Agreements govern our obligations with respect to any services, products, or access that We make available to You; these Website Terms of Use shall not be construed to change the terms of any such Other Agreements. In the event of a conflict between these Website Terms of Use and any Other Agreement, then, the Other Agreement takes precedence.",
    },
  },
  trademarks: {
    heading: "TRADEMARKS:",
    list: {
      list_1:
        "Our name, logos, and all other trademarks we utilise, are our entities' registered trademarks. You are permitted to use Our word, or mark in text, to reference Us, Our services and products, fairly and honestly, as long as they follow the guidelines listed below.",
      list_2:
        "Our logos can only be used by us or/and our partners and our licensees who have written agreement signed with us allowing logo use.",
      list_3:
        "Any other usage is not permitted with our logos, nor with our stylized marks, without our express written permission.",
      list_4:
        "Our trademarks may not be used by you in a way that is likely to confuse or mislead third parties, which includes concerning Your relationship with Ifocus Technologies  any endorsement or sponsorship of Your firm or company, your services or products, or Your services or products’ origin.",
      list_5: "Any such usage is strictly illegal and is punishable by law.",
    },
  },
  linkingIfocus: {
    heading: "LINKING TO IFOCUS TECHNOLOGIES WEBSITE:",
    description: {
      description_1:
        "You may create links to Our Website from other websites for your convenience. If you provide anyone with a link to Our Website, you must comply with the following conditions and all applicable laws unless an express agreement indicates otherwise:",
    },
    list: {
      list_1: "You may link to Materials but not copy them.",
      list_2:
        "You are not permitted to create a browser or border environment around any Materials.",
      list_3:
        "You agree not to imply that We either endorse or are associated with any website which links to Our Website or any services, products or information offered via that website.",
      list_4:
        "You are not permitted to misrepresent Your relationship with Us.",
      list_5:
        "You are not permitted to present inaccurate or misleading information about Us and/or Our services or products.",
      list_6:
        "The website which links to Our Website shall not contain anything that could be regarded as objectionable, controversial, offensive, distasteful, a violation of privacy rights, unlawful, or overtly sexual.",
      list_7:
        "Only content suitable for all age groups shall be included on the website that links to Our Website.",
    },
  },
  accoutsSecutity: {
    heading: "ACCOUNTS, PASSWORDS & SECURITY:",
    description: {
      description_1:
        "You may be required to register an account in order to take advantage of certain Site features or services. You are solely responsible for safeguarding the confidentiality of your account information, which includes your password, as well as for any and all the activity that occur under your account.",
      description_2:
        "Whenever you become aware of unauthorized access to your password or account, or a security breach, you agree to immediately tell IFOCUS TECHNOLOGIES, so that we can put measures in place to prevent future occurrences. If someone else uses your IFOCUS ID, password, or account, you could be held liable for any losses incurred by IFOCUS or any other user or visitor to the site as a result of your failure to keep your account information safe and confidential.",
      description_3:
        "You shall not use anybody else's IFOCUS TECHNOLOGIES account, ID or password, without the consent or express permission and approval of the owner of that Apple ID, login information, account or password, at any time. IFOCUS TECHNOLOGIES cannot and will not be held liable for any damage or loss caused by your inability to meet these obligations.",
      description_4:
        "IFOCUS TECHNOLOGIES’s website may contain links to third-party websites. We are not responsible for the content, accuracy, or practices of these websites.",
    },
  },
  indementification: {
    heading: "INDEMNIFICATION:",
    description: {
      description_1:
        "You agree to these terms to indemnify, defend and hold IFOCUS TECHNOLOGIES harmless from any and all claims or liabilities, losses, expenses, costs, damages, fees (which include Attorney's reasonable fees) that such parties may incur as a result of or arising from a violation of these Website Terms of Use by You or anyone using Your account. Any matter entitled to indemnification by You is subject to Our exclusive defence and control, in which case you undertake to cooperate with Us in our defence of such claim.",
      description_2:
        "We reserve the right to change these Website Terms of Service at any time. We reserve the right to amend these Website Terms of Use (and any other documents referenced in or linked to from these Website Terms of Use) at any time and without notice. We shall notify you of any significant changes to Our website by means of a notice that appears here. We reserve the right to modify, suspend, or discontinue Our Website and/or any products, programs, services, and other offerings provided through or in connection with Our Website at any time without notice to You, and if You object to any such changes, your only recourse shall be to stop using Our Website. When you continue to use Our Website after notice of any such changes, it indicates You acknowledge and agree to abide by the terms and conditions of any such modifications.",
    },
  },
  desclaimers: {
    heading: "DISCLAIMERS:",
    description: {
      description_1:
        'BECAUSE OUR WEBSITE IS PROVIDED "AS-IS" AND "WITH ALL FAULTS," THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF OUR WEBSITE RESTS WITH YOU. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, WITH RESPECT TO OUR WEBSITE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.',
      description_2:
        'WE DO NOT GUARANTEE AND CANNOT ENSURE THAT THE WEBSITE WILL BE ERROR-FREE OR FREE OF PROBLEMS. This means that we offer no guarantee that Our Website will meet Your needs or that it will be available without interruption, timely, secure, or error-free or that any defects in Our Website will be remedied without limiting the generality of the foregoing. Neither the outcomes produced from using Our website nor the accuracy or reliability of any information accessed through Our website are guaranteed by us. Any advice or information, whether oral or written, obtained by You through our website or from us, or our directors, officers, agents or employees, (collectively, "IFOCUS"), will not create any guarantee/warranty.',
    },
  },
};

export const feedbackInfo = {
  heading: "FEEDBACK & INFORMATION:",
  description_1:
    "If you would like to contact us for feedback or information or for any reason, please send an email to",
  email_link: "info@testserv.ai",
  description_2:
    "Any comments, feedback, data or information you provide us on this website will be considered non-confidential. IFOCUS TECHNOLOGIES  will have full access and use of this information.",
};

// Footer Section Constants

export const testservDescription =
  "TestServ is the ultimate tool for generating dynamic test data, crafting robust test cases, and elevating test coverage to discover even the most elusive edge cases.";

export const companyLinks = [
  { href: "/about_us", text: "About Us" },
  { href: "/contact_us", text: "Contact" },
];

export const featuresLinks = [
  { href: "/api_automation", text: "API Automation" },
  { href: "/fe_dashboard", text: "FE Dashboard Report" },
];

export const resourcesLinks = [
  { href: "/blogs", text: "Blogs" },
  { href: "/terms_and_conditions", text: "Terms and Conditions" },
];

export const newsLetterContent = {
  title: "Newsletter",
  description:
    "Signup for our newsletter to get the latest news, updates, and special offers in your inbox.",
  buttonText: "Subscribe",
  privacyMessage: "Your email is safe with us. We don't spam.",
};

export const failedSubmit = "Failed to subscribe. Please try again later.";

// Sub Footer Constants
export const copyrightText = "© Copyright 2024 - Design by TestServ";

// Thank You Page Constants

export const thankYouHeading = "Thank You for Choosing TestServ!";
export const thanksForContact =
  "We've received your contact details. Our team will be in touch shortly to discuss your testing needs. Stay tuned!";
export const thanksForDemo =
  "It's official - your demo session is booked! Get ready to dive into the distinct advantages of our solutions";
export const thanksForSubscribe = "Thank you for Subscribing!";
export const back = "Back";

// Form Validation URLs
export const contactURL =
  "https://testapi.testserv.ai/api/UserInfo/SaveContactInfo";
export const demoURL = "https://testapi.testserv.ai/api/UserInfo/ScheduleDemo";
export const newsLetterURL =
  "https://testapi.testserv.ai/api/UserInfo/SaveNewsLetter";

// Formats
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

// Reusable Functions
export const TypingEffect = ({ strings }) => {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentTypedLetters, setCurrentTypedLetters] = useState("");

  useEffect(() => {
    const currentString = strings[currentStringIndex];

    if (currentTypedLetters.length < currentString.length) {
      const timeout = setTimeout(() => {
        setCurrentTypedLetters(
          currentString.slice(0, currentTypedLetters.length + 1)
        );
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [currentTypedLetters, currentStringIndex, strings]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentStringIndex((prevIndex) => (prevIndex + 1) % strings.length);
      setCurrentTypedLetters("");
    }, 2000);

    return () => clearTimeout(timeout);
  }, [currentStringIndex, strings]);

  return <span>{currentTypedLetters}</span>;
};
